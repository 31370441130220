<template>
  <div class="">
    <span v-if="selectNum==1">
      <div @click="chooseFile()" class="default">
        <el-image shape="square" :size="100" :fit="fit" :src="selectData?selectData[0]:''" icon="el-icon-upload" style="width: 100px; height: 100px; background-color: #f5f5f5; cursor: pointer;">
          <div slot="error" class="image-slot">
            <i class="el-icon-upload"></i>
          </div>
        </el-image>
        <div class="cover">重新选择</div>
      </div>
    </span>
    <span v-else>
      <div class="picbox" v-if="selectData && selectData.length > 0">
        <div class="item" v-for="(item, index) in selectData" :key="index">
          <el-image shape="square" :size="100" :fit="fit" :src="item" icon="el-icon-upload" style="width: 100px; height: 100px; background-color: #f5f5f5;">
            <div slot="error" class="image-slot">
              <i class="el-icon-upload"></i>
            </div>
          </el-image>
          <div class="del" @click="delPic(index)"><i class="el-icon-delete"></i></div>
        </div>
        <div @click="chooseFile()" class="default" v-if="selectData.length < selectNum">
          <el-image shape="square" :size="100" :fit="fit" :src="avatar" icon="el-icon-upload" style="width: 100px; height: 100px; background-color: #f5f5f5; cursor: pointer;">
            <div slot="error" class="image-slot">
              <i class="el-icon-upload"></i>
            </div>
          </el-image>
        </div>
      </div>
      <div v-else @click="chooseFile()" class="default">
        <el-image shape="square" :size="100" :fit="fit" :src="avatar" icon="el-icon-upload" style="width: 100px; height: 100px; background-color: #f5f5f5; cursor: pointer;">
          <div slot="error" class="image-slot">
            <i class="el-icon-upload"></i>
          </div>
        </el-image>
      </div>
    </span>
    <el-dialog title="管理图片" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" custom-class="dialog" width="740px" :append-to-body="true">
      <FileUpload :chooseNum="selectNum" :chooseFunc="fileChoose"></FileUpload>
    </el-dialog>
  </div>
</template>

<script>
  import FileUpload from './FileUpload'
  export default {
    data() {
      return {
        dataList: false,
        dialogFormVisible: false,
        avatar: '',
        fit: 'contain'
      }
    },
    components: {
      FileUpload
    },
    props: {
      selectNum: Number,
      selectFunc: Function,
      deleteFunc: Function,
      selectData: Array,
      idx: Number
    },
    created() {
    },
    mounted() {
    },
    methods: {
      chooseFile() {
        this.dialogFormVisible = !this.dialogFormVisible
      },
      fileChoose(data) {
        this.selectFunc(data,this.idx)
        this.chooseFile()
      },
      delPic(index) {
        this.deleteFunc(index)
      }
    }
  }
</script>

<style scoped>
  .picbox .item,.default { float: left; margin: 0 10px 10px 0; position: relative;}
  .default .cover { position: absolute; bottom: 10px; height: 30px; line-height: 30px; text-align: center; color: #fff; background-color: rgb(22 21 21 / 50%); width: 100%; border-radius: 0 0 4px 4px;}
  .picbox .del {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
    background-color: rgba(255,255,255,0.7);
    z-index: 9;
    text-align: center;
    width: 30px;
    height: 30px;
  }
</style>
